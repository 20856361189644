// Angular modules
import { Injectable } from '@angular/core';

import { DataListConfigurerHelper } from '@helpers/datalist-configurer.helper';
import { Role } from '../models';
import {
  DataListOperation,
  DataListHeader,
  DataListHeaderItem,
  ResponsiveBreakpoints,
  DataListPagination,
  DataListRowOperationAlias,
  DataList,
  DataListItem
} from '@models/index';

@Injectable()
export class RoleDataListConfigurerHelper extends DataListConfigurerHelper<Role>{

  public getDefaulDataList(): DataList<DataListItem<Role>> {

    const toret: DataList<DataListItem<Role>> = new DataList();

    toret.searchPlaceholder= 'Buscar por nombre';
    toret.name = '------TITULO-----------';
    toret.useCheckbox = true;
    toret.useSearch = true;

    return toret;
  }

  public getDefaulDataListHeader(): DataListHeader {
    const toret: DataListHeader = new DataListHeader();

    toret.canToggleItems = true;
    toret.pagination = new DataListPagination();
    toret.headers = this.getDefaultDataListHeaderItems();

    return toret;
  }

  public getDefaultDataListHeaderItems(): DataListHeaderItem[] {
    const toret: DataListHeaderItem[] = [
      {
        alias: 'name',
        title: 'Nombre',
        sortable: true,
        visible: true,
      }
    ];

    return toret;
  }

  public getDefaultDataListOperations(): DataListOperation[] {
    return [
      {
        alias: DataListRowOperationAlias.ViewDetails,
        icon: 'eye',
        title: 'Ver detalle',
      },
      // {
      //   alias: DataListRowOperationAlias.Edit,
      //   icon: 'pen',
      //   title: 'Modificar',
      // },
      // {
      //   alias: DataListRowOperationAlias.Delete,
      //   icon: 'na',
      //   title: 'Deshabilitar',
      // },
    ];
  }

  public getDefaultBulkOperations(): DataListOperation[] {
    return [
        // {
        // "alias": DataListRowOperationAlias.Email,
        //   "title": "Send Email",
        // },
        // {
        //   "alias": DataListRowOperationAlias.ChangeGroup,
        //   "title": "Change Group",
        // },
        // {
        //   "alias": DataListRowOperationAlias.Suspend,
        //   "title": "Suspend Role",
        // },
        {
          "alias": DataListRowOperationAlias.Delete,
          "title": "Deshabilitar",
        },
      ]
  }

}
