// Angular modules
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '@services/store.service';
import { NGXLogger } from 'ngx-logger';

// Models
import { User } from '@security/models/user.model';

// Modal
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Base class
import { BaseQuickviewComponent } from '@pages/base/base-quickview.component';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'lockbin-user-quickview',
  templateUrl: './user-quickview.component.html',
  styles: [
  ]
})
export default class UserQuickviewComponent extends BaseQuickviewComponent<User> implements OnInit {

  @Input() data: User | undefined;
  @Output() submitData: EventEmitter<any> = new EventEmitter();
  @Output() submitClose: EventEmitter<null> = new EventEmitter();


  // -------------------------------------------------------------------------------
  // NOTE Defined reactive form on Angular style ------------------------------------
  // -------------------------------------------------------------------------------
  protected override formGroup!: FormGroup<{
    name: FormControl<string>;
  }>;



  // -------------------------------------------------------------------------------
  // NOTE Constructor & Init -------------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override router: Router,
    protected override logger: NGXLogger,
    protected override translateService: TranslateService,
    protected override activeModal: NgbActiveModal,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,

  ) {

    super(location, titleService, storeService, router, logger, translateService, activeModal, activatedRoute, eventBusService);

  }

  override ngOnInit(): void {

    if (!this.data) return;

    this.entity = this.data;

    this.setupRoutes();

    // setup FormGroup with validators, default values, etc.
    this.initFormGroup();

    // reset form to default values from the 'this.entity' value
    // this.setDefaultValuesToFormGroup();
  }


  /**
   * Init formGroup with a helper
   */
  private initFormGroup() {


  }

  /**
   * Set routes to list/edit after page operation (add or edit) is done
   */
  private setupRoutes() {
    this.routeToEdit = '/administracion/seguridad/usuarios-edit/';
  }


}
