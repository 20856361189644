// Angular modules
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import Swal from 'sweetalert2';

// Services
import { StoreService } from '@services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

// Models
import { Role } from '@security/models/role.model';
import { RoleService } from '@security/services/role.service';

// Validators
import { UniqueRoleNameValidator } from '@security/validators/unique-role-name-validator.service';

// Helpers
import { RoleFormGroupHelper } from '@security/helpers/role-form-configurer.helper';

// Base class
import { BaseAddComponent } from '@pages/base/base-add.component';
import { EventBusService } from '@services/event-bus.service';


@Component({
  selector: 'lockbin-role-add',
  templateUrl: './role-add.component.html',
  styles: [],
})
export class RoleAddComponent extends BaseAddComponent<Role> implements OnInit {

  // -------------------------------------------------------------------------------
  // NOTE Defined reactive form on Angular style ------------------------------------
  // -------------------------------------------------------------------------------
  protected override formGroup!: FormGroup<{
    name: FormControl<string>;
    code: FormControl<string>;
    cee: FormControl<boolean>;
    euro: FormControl<boolean>;
    sepa: FormControl<boolean>;
  }>;


  // -------------------------------------------------------------------------------
  // NOTE Constructor & Init -------------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override router: Router,
    protected override logger: NGXLogger,
    protected override translateService: TranslateService,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
    private roleService: RoleService,
    private uniqueRoleNameValidator: UniqueRoleNameValidator,
  ) {

    super(location, titleService, storeService, router, logger, translateService, activatedRoute, eventBusService);

  }


  override ngOnInit(): void {

    // set routes to list/edit after page operation (add or edit) is done
    this.setupRoutesPostOperation();

    // setup FormGroup with validators, default values, etc.
    this.initFormGroup();

    // get params. Only are received when operation is edit
    this.activatedRoute.params
      .subscribe(params => {

        const id = params['id'];

        this.setupPageForAddOrEdit(id); //title, subtitle, and others

        if (id) { // is editing

          this.roleService.findById(id)
            .subscribe((itemToUpdate: Role) => {

              // set entity value for update. If entity not exist (this shouldn't happen), initialize the variable to a new object
              this.entity = (itemToUpdate) ? itemToUpdate : new Role();

              // reset form to default values from the 'this.entity' value
              this.setDefaultValuesToFormGroup();

            });
        } else { // is adding

          // set entity value for add
          this.entity = new Role();

          // reset form to default values from the 'this.entity' value
          this.setDefaultValuesToFormGroup();

        }
      })
  }


  /**
   * Setups page for add or edit based on id
   * @param id
   */
  private setupPageForAddOrEdit(id: any) {

    if (id) {
      this.operationTitle = 'Edición de Rol'
      this.operationSubtitle = 'Modifique los datos del rol'
    } else {
      this.operationTitle = 'Nuevo Rol'
      this.operationSubtitle = 'Rellene los datos del rol'
    }

  }


  /**
   * Init formGroup with a helper
   */
  private initFormGroup() {
    this.formGroup = new RoleFormGroupHelper(this.uniqueRoleNameValidator).getRoleFormGroup();
  }


  /**
   * Set routes to list/edit after page operation (add or edit) is done
   */
  private setupRoutesPostOperation() {
    this.routeToEdit = '/dministracion/seguridad/roles-edit/';
    this.routeToList = '/administracion/seguridad/roles-list/';
  }


  // -------------------------------------------------------------------------------
  // Custom validations ------------------------------------------------------------
  // -------------------------------------------------------------------------------
  /**
   * Evals if a role name exits.
   * Due to this page support add/edit operations, need to check entity id's with same 'name' for the update operation
   */
  public hasErrorDueToRoleNameExists(): boolean {

    return this.formGroup.controls.name.hasError('roleNameExists')
      && !this.formGroup.controls.name.errors?.['entityIdsWithSameTerm']?.includes(this.entity.id)
  }


  // -------------------------------------------------------------------------------
  // NOTE Actions ------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public async onClickSubmit(): Promise<void> {

    const titleText = this.entity.id > 0 ? this.translateService.instant('ALERT_UPDATE_HEADER') : this.translateService.instant('ALERT_ADD_HEADER');

    Swal.fire({
      title: titleText,
      text: this.translateService.instant('ALERT_CONFIRM_TEXT'),
      showClass: {
        popup: 'animated fadeInDown faster',
      },
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
      cancelButtonText: this.translateService.instant('BTN_CANCEL'),
    }).then((result) => {

      if (result.isConfirmed) {
        this.doSave();
      }
    })
  }


  private doSave() {

    this.setFormDataValuesToEntity();

    const obs$ = (this.entity.id === 0) ? this.roleService.add(this.entity) : this.roleService.update(this.entity);


    this.save(obs$);

  }
}
