<div class="nk-block-head nk-block-head-sm">
  <div class="nk-block-between">
    <div class="nk-block-head-content">
      <h3 class="nk-block-title page-title">{{ this.operationTitle }}</h3>
      <div class="nk-block-des text-soft">
        <p>{{ this.operationSubtitle }}</p>
      </div>
    </div><!-- .nk-block-head-content -->
  </div><!-- .nk-block-between -->
</div><!-- .nk-block-head -->

<lockbin-alert [isFilled]="false" [dismissible]="true"></lockbin-alert>

<form [formGroup]="formGroup" autocomplete="on">
  <div class="nk-block">
      <div class="card card-bordered">
          <div class="card-inner-group">
              <div class="card-inner">
                  <div class="nk-block-head">
                      <div class="nk-block-head-content">
                          <h5 class="title nk-block-title">Datos Básicos</h5>
                          <!-- <p>Add common infomation like Name, Email etc </p> -->
                      </div>
                  </div>
                  <div class="nk-block">
                      <div class="row gy-4">
                          <div class="col-xxl-5 col-md-4">
                              <div class="form-group">
                                  <label class="form-label" for="name">Nombre</label>
                                  <div class="form-control-wrap">
                                      <input type="text" class="form-control" id="name" placeholder="Nombre"
                                        formControlName="name"
                                        [ngClass]="{ 'is-invalid' : hasErrorDueToRoleNameExists() && formGroup.controls.name.touched }">

                                        <div class="invalid-feedback" *ngIf="formGroup.controls.name.hasError('required')">
                                          {{ 'FIELD_REQUIRED' | translate }}
                                        </div>
                                        <div class="invalid-feedback" *ngIf="hasErrorDueToRoleNameExists()">
                                          {{ 'FIELD_NAME_EXISTS' | translate }}
                                        </div>
                                  </div>
                              </div>
                          </div><!--col-->
                      </div><!--row-->
                  </div>
              </div><!-- .card-inner -->

              <div class="card-inner">
                  <div class="nk-block">
                      <div class="row gy-4">
                          <div class="col">

                            <div class="d-flex justify-content-between">
                              <button (click)="onClickSubmit()"
                                class="btn btn-primary"
                                [lockbinButtonLoading]="this.formGroup.pending || this.storeService.getIsLoading()"
                                [disabled]="formGroup.pending || formGroup.invalid">
                                <span>{{ 'BTN_SAVE' | translate }}</span>
                                <em class="icon ni ni-save"></em>
                              </button>
                              <button (click)="goBack()" class="btn btn-outline-light bg-white">
                                <em class="icon ni ni-arrow-left"></em>
                                <span>{{ 'BTN_BACK' | translate }}</span>
                              </button>
                            </div>

                          </div>
                      </div><!--row-->
                  </div>
              </div><!-- .card-inner -->

          </div>
          <div *ngIf="formGroup.invalid">
            <div *ngIf="formGroup.controls.name.errors && formGroup.controls.name.touched">
              {{ 'FIELD_REQUIRED' | translate }} --
            </div>
          </div>
      </div><!-- .card -->

  </div><!-- .nk-block -->
</form>
