// Angular modules
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';

// External modules
import { Observable, delay, of, map, catchError } from 'rxjs';

// Services
import { RoleService } from '../services/role.service';

// Models
import { QueryParameters } from '@models/index';
import { QueryResults } from '@models/api-request.model';
import { Role } from '../models';

// Environment
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class UniqueRoleNameValidator implements AsyncValidator {

  constructor(
    private roleService: RoleService,
  ) { }


  validate(control: AbstractControl<any, any>): Observable<ValidationErrors | null> {

    const queryParamsToQuery = this.getQueryParamsPrepared(control.value);


    return this.roleService.find(queryParamsToQuery)
      .pipe(
          delay( environment.delayFake ),
          map((resp: QueryResults<Role>) => {

            if(resp?.items?.length === 0) {
              return null; // not exits any entity with same 'term'
            } else {

              // array with all entity ids that match with query, that is 'with same term'
              const entityIdsWithSameTerm = resp.items.map( (entity: Role) => entity.id )

              return { roleNameExists: true, entityIdsWithSameTerm };
            }

          }),
          catchError(() => of([]))
      )
  }


  private getQueryParamsPrepared(value: any): QueryParameters {

    const toret: QueryParameters = {
      pageSize: 1, // only one result in response is needed to check if a role name exits
      filterByParams: {
        name: value
      }
    }

    return toret;

  }


  registerOnValidatorChange?(fn: () => void): void {
    throw new Error('Method not implemented.');
  }
}
