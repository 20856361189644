// Angular modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Internal modules
import { SecurityRoutingModule } from './security-routing.module';
import { SharedModule } from '../shared/shared.module';

// Components
import { RolesComponent } from './roles/roles.component';
import { SecurityComponent } from './security.component';
import { UserAddComponent } from './users/pages/user-add/user-add.component';
import { UserListComponent } from './users/pages/user-list/user-list.component';
import UserQuickviewComponent from './users/pages/user-quickview/user-quickview.component';
import { RoleAddComponent } from './roles/pages/role-add/role-add.component';
import { RoleListComponent } from './roles/pages/role-list/role-list.component';
import RoleQuickviewComponent from './roles/pages/role-quickview/role-quickview.component';

// External modules

@NgModule({
  declarations: [
    RolesComponent,
    SecurityComponent,
    UserAddComponent,
    UserListComponent,
    UserQuickviewComponent,
    RoleAddComponent,
    RoleListComponent,
    RoleQuickviewComponent
  ],
  imports: [

    CommonModule,
    SecurityRoutingModule,

    SharedModule,

    // External modules
  ],
})
export class SecurityModule { }
