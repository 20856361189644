// Angular modules
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

// Services
import { StoreService } from '@services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '@security/services/user.service';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';
import { RoleService } from '@security/services/role.service';

// Models
import { User } from '@security/models/user.model';
import { SearchStrategy } from '@enums/search-strategy.enum';
import { QueryParameter } from '@models/api-request.model';

// Validators
import { UniqueUserNameValidator } from '@security/validators/unique-user-name-validator.service';

// Helpers
import { UserFormGroupHelper } from '@security/helpers/user-form-configurer.helper';

// Base class
import { BaseAddComponent } from '@pages/base/base-add.component';
import { CustomAutocompleteComponent } from '@autocomplete/custom-autocomplete/custom-autocomplete.component';
import { Role } from '@security/models';
import { EventBusService } from '@services/event-bus.service';


@Component({
  selector: 'lockbin-user-add',
  templateUrl: './user-add.component.html',
  styles: [],
})
export class UserAddComponent extends BaseAddComponent<User> implements OnInit {

  @ViewChild('customAutocompleteCustomer') customAutocompleteCustomer!: CustomAutocompleteComponent;
  @ViewChild('customAutocompleteRole') customAutocompleteRole!: CustomAutocompleteComponent;

  searcherCustomer$!: Observable<any>; //searcher used in Autocomplete component
  searcherRole$!:     Observable<any>; //searcher used in Autocomplete component


  // -------------------------------------------------------------------------------
  // NOTE Defined reactive form on Angular style ------------------------------------
  // -------------------------------------------------------------------------------
  protected override formGroup!: FormGroup<{

    login:        FormControl<string>;
    name:         FormControl<string>;
    code:         FormControl<string>;
    email:        FormControl<string>;
    newPassword:  FormControl<string>;
    newPassword2: FormControl<string>;
    customerId:   FormControl<number>;
    roleId:       FormControl<number>;
    customerName: FormControl<string>;
    roleName:     FormControl<string>;


  }>;


  // -------------------------------------------------------------------------------
  // NOTE Constructor & Init -------------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override router: Router,
    protected override logger: NGXLogger,
    protected override translateService: TranslateService,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
    private userService: UserService,
    private customerService: CustomerService,
    private roleService: RoleService,
    private uniqueUserNameValidator: UniqueUserNameValidator,
  ) {

    super(location, titleService, storeService, router, logger, translateService, activatedRoute, eventBusService);

  }


  override ngOnInit(): void {

    this.storeService.setIsLoading(true);

    // setup searchers for autocompletes
    this.setupSearchers();

    // set routes to list/edit after page operation (add or edit) is done
    this.setupRoutesPostOperation();

    // setup FormGroup with validators, default values, etc.
    this.initFormGroup();

    // get params. Only are received when operation is edit
    this.activatedRoute.params
      .subscribe(params => {

        const id = params['id'];

        this.setupPageForAddOrEdit(id); //title, subtitle, and others

        if (id) { // is editing

          this.userService.findById(id, SearchStrategy.EAGER)
            .subscribe((itemToUpdate: User) => {

              // set entity value for update. If entity not exist (this shouldn't happen), initialize the variable to a new object
              this.entity = (itemToUpdate) ? itemToUpdate : new User();

              // reset form to default values from the 'this.entity' value
              this.setDefaultValuesToFormGroup();
              this.setTransientValuesToFormGroup();
              this.setAsyncValidators();

              // set values to autocomplete inputs fields and formGroup properties asociated with autocomplete
              this.setValuesToAutocompletes();

              this.storeService.setIsLoading(false);


            });
        } else { // is adding

          // set entity value for add
          this.entity = new User();

          // reset form to default values from the 'this.entity' value
          this.setDefaultValuesToFormGroup();

          this.setAsyncValidators();

          this.storeService.setIsLoading(false);
        }
      })
  }


  setupSearchers() {
    const queryParams: QueryParameter = {};

    this.searcherCustomer$ = this.customerService.find(queryParams);
    this.searcherRole$ = this.roleService.find(queryParams);

  }

  /**
   * Setups page for add or edit based on id
   * @param id
   */
  private setupPageForAddOrEdit(id: any) {

    if (id) {
      this.operationTitle = 'Edición de Usuario'
      this.operationSubtitle = 'Modifique los datos del usuario'
    } else {
      this.operationTitle = 'Nuevo Usuario'
      this.operationSubtitle = 'Rellene los datos del usuario'
    }

  }


  /**
   * Init formGroup with a helper
   */
  private initFormGroup() {
    this.formGroup = new UserFormGroupHelper().getUserFormGroup();
  }



  private setAsyncValidators() {

    this.formGroup.controls.name.addAsyncValidators
      (this.uniqueUserNameValidator.validateUniqueUserNameValidator(this.entity.id))

  }


  /**
   * Set routes to list/edit after page operation (add or edit) is done
   */
  private setupRoutesPostOperation() {
    this.routeToEdit = '/dministracion/seguridad/usuarios-edit/';
    this.routeToList = '/administracion/seguridad/usuarios-list/';
  }

  /**
 * Set values to autocomplete input field once the value has been received from service
 */
  private setValuesToAutocompletes() {

    if (this.entity.customer?.id > 0)
      this.customAutocompleteCustomer.fieldValue = this.entity.customer?.name;

    if(this.entity.roles?.length > 0) {
      this.customAutocompleteRole.fieldValue = this.entity.roles[0].name;
    }


  }
  // -------------------------------------------------------------------------------
  // Custom validations ------------------------------------------------------------
  // -------------------------------------------------------------------------------
  /**
   * Evals if a user name exits.
   * Due to this page support add/edit operations, need to check entity id's with same 'name' for the update operation
   */
  public hasErrorDueToUserNameExists(): boolean {

    return this.formGroup.controls.name.hasError('userNameExists')
      && !this.formGroup.controls.name.errors?.['entityIdsWithSameTerm']?.includes(this.entity.id)
  }


  // -------------------------------------------------------------------------------
  // #region NOTE Actions ----------------------------------------------------------
  // -------------------------------------------------------------------------------
  // set values to form when an item is selected

  doSelectItemAutocompleteCustomer(selectedItem: any) {

    if (selectedItem?.item?.id) {
      this.formGroup.controls['customerId'].setValue(selectedItem.item.id);
      this.formGroup.controls['customerName'].setValue(selectedItem.item.name);
    }
  }

  // set values to form when an item is selected
  doSelectItemAutocompleteRole(selectedItem: any) {

    if (selectedItem?.item?.id) {
      this.formGroup.controls['roleId'].setValue(selectedItem.item.id);
      this.formGroup.controls['roleName'].setValue(selectedItem.item.name);
    }
  }
  public async onClickSubmit(): Promise<void> {

    const titleText = this.entity.id > 0 ? this.translateService.instant('ALERT_UPDATE_HEADER') : this.translateService.instant('ALERT_ADD_HEADER');

    Swal.fire({
      title: titleText,
      text: this.translateService.instant('ALERT_CONFIRM_TEXT'),
      showClass: {
        popup: 'animated fadeInDown faster',
      },
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
      cancelButtonText: this.translateService.instant('BTN_CANCEL'),
    }).then((result) => {

      if (result.isConfirmed) {
        this.doSave();
      }
    })
  }


  private doSave() {

    this.setFormDataValuesToEntity();

    const obs$ = (this.entity.id === 0) ? this.userService.add(this.entity) : this.userService.update(this.entity);


    this.save(obs$);

  }


  // This method is called from setFormDataValuesToEntity in BaseClass
  protected override transformTransientValuesToEntityValue() {

    const roleId = this.formGroup.controls['roleId'].value;

    if (roleId) {
      const roleToAdd = new Role();
      roleToAdd.id = roleId;
      this.entity.roles = [ roleToAdd ];
    }

  }


  protected override setTransientValuesToFormGroup() {

    if(this.entity.customer?.name) {
    this.formGroup.controls['customerName'].setValue(this.entity.customer?.name);
    }

    if(this.entity.roles?.length > 0) {
      this.formGroup.controls['roleId'].setValue(this.entity.roles[0].id);
      this.formGroup.controls['roleName'].setValue(this.entity.roles[0].name);
    }

  }
}

  // #endregion --------------------------------------------------------------------
