// Angular modules
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UniqueRoleNameValidator } from '@security/validators/unique-role-name-validator.service';

// Validators

@Injectable()
export class RoleFormGroupHelper {

  constructor(
    private unqueRoleNameValidator: UniqueRoleNameValidator,

  ) {

  }

  public getRoleFormGroup(): FormGroup {

      return new FormGroup({
      name: new FormControl<string>(
        { value: '', disabled: false },
        {
          validators: [Validators.required, Validators.maxLength(255)],
          nonNullable: true,
          asyncValidators: [this.unqueRoleNameValidator.validate.bind(this.unqueRoleNameValidator)],
          updateOn: 'blur',
        }
      )
    })
  }
}


