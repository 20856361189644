<div class="nk-block-head nk-block-head-sm">
  <div class="nk-block-between">
    <div class="nk-block-head-content">
      <h3 class="nk-block-title page-title">{{ this.operationTitle }}</h3>
      <div class="nk-block-des text-soft">
        <p>{{ this.operationSubtitle }}</p>
      </div>
    </div><!-- .nk-block-head-content -->
  </div><!-- .nk-block-between -->
</div><!-- .nk-block-head -->

<lockbin-alert [isFilled]="false" [dismissible]="true"></lockbin-alert>
<!-- NOTE form -->
<form [formGroup]="formGroup" autocomplete="on">
  <div class="nk-block">
    <div class="card card-bordered">
      <div class="card-inner-group">
        <div class="card-inner">
          <div class="nk-block-head">
            <div class="nk-block-head-content">
              <h5 class="title nk-block-title">Datos Básicos</h5>
              <!-- <p>Add common infomation like Name, Email etc </p> -->
            </div>
          </div>
          <div class="nk-block">
            <div class="row gy-4">
              <div class="col-xxl-5 col-md-4">
                <div class="form-group">
                  <label class="form-label" for="name">Nombre completo <span class="form-required">*</span></label>
                  <div class="form-control-wrap">
                    <input type="text" class="form-control" id="name" placeholder="Nombre completo" formControlName="name"
                      [ngClass]="{ 'is-invalid' : hasErrorDueToUserNameExists() && formGroup.controls.name.touched }">

                    <div class="invalid-feedback" *ngIf="formGroup.controls.name.hasError('required')">
                      {{ 'FIELD_REQUIRED' | translate }}
                    </div>
                    <div class="invalid-feedback" *ngIf="hasErrorDueToUserNameExists()">
                      {{ 'FIELD_NAME_EXISTS' | translate }}
                    </div>
                  </div>
                </div>
              </div><!--col-->

              <div class="col-xxl-3 col-md-4">
                <lockbin-custom-autocomplete id="customAutocompleteRole" #customAutocompleteRole
                  (onSelectedItem)="doSelectItemAutocompleteRole($event)" [searcher]="searcherRole$"
                  [isRequired]="true"
                  labelInputText="Rol" placeholderText="Buscar rol..." fieldToFilter="name" fieldToShow="name">
                </lockbin-custom-autocomplete><!--custom autocomplete-->
              </div><!--col-->

              <div class="col-xxl-3 col-md-4">
                <div class="form-group">
                  <label class="form-label" for="code">Código</label>
                  <div class="form-control-wrap">
                    <input type="text" class="form-control" id="code" placeholder="Código" formControlName="code"
                      [ngClass]="{ 'is-invalid' : formGroup.controls.code.errors && formGroup.controls.code.touched }">

                    <div class="invalid-feedback" *ngIf="formGroup.controls.code.hasError('required')">
                      {{ 'FIELD_REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div><!--col-->

              <div class="col-xxl-3 col-md-4">
                <div class="form-group">
                  <label class="form-label" for="login">Login <span class="form-required">*</span></label>
                  <div class="form-control-wrap">
                    <input type="text" class="form-control" id="login" placeholder="Login" formControlName="login"
                      [ngClass]="{ 'is-invalid' : formGroup.controls.login.errors && formGroup.controls.login.touched }">

                    <div class="invalid-feedback" *ngIf="formGroup.controls.login.hasError('required')">
                      {{ 'FIELD_REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div><!--col-->

              <div class="col-xxl-3 col-md-4">
                <div class="form-group">
                  <label class="form-label" for="password">Password <span class="form-required">*</span></label>
                  <div class="form-control-wrap">
                    <input type="password" class="form-control" id="newPassword" placeholder="Password"
                      formControlName="newPassword"
                      [ngClass]="{ 'is-invalid' : formGroup.controls.newPassword.touched && (formGroup.controls.newPassword.errors)  }">

                    <div class="invalid-feedback" *ngIf="formGroup.controls.newPassword.hasError('required')">
                      {{ 'FIELD_REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div><!--col-->
              <div class="col-xxl-3 col-md-4">
                <div class="form-group">
                  <label class="form-label" for="password">Repetir Password <span class="form-required">*</span></label>
                  <div class="form-control-wrap">
                    <input type="password" class="form-control" id="newPassword2" placeholder="Password"
                      formControlName="newPassword2"
                      [ngClass]="{ 'is-invalid' : formGroup.controls.newPassword2.touched && (formGroup.controls.newPassword2.errors || formGroup.hasError('passwordAreDiferents'))  }">

                    <div class="invalid-feedback" *ngIf="formGroup.controls.newPassword2.hasError('required')">
                      {{ 'FIELD_REQUIRED' | translate }}
                    </div>
                    <div class="invalid-feedback" *ngIf="formGroup.hasError('passwordAreDiferents')">
                      {{ 'PASSWORD_NOT_SAME' | translate }}
                    </div>

                  </div>
                </div>
              </div><!--col-->


              <div class="col-xxl-7 col-md-8">
                <div class="form-group">
                  <label class="form-label" for="email">Email</label>
                  <div class="form-control-wrap">
                    <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email"
                      [ngClass]="{ 'is-invalid' : formGroup.controls.email.errors && formGroup.controls.email.touched }">

                    <div class="invalid-feedback" *ngIf="formGroup.controls.email.hasError('required')">
                      {{ 'FIELD_REQUIRED' | translate }}
                    </div>
                    <div class="invalid-feedback" *ngIf="formGroup.controls.email.hasError('email')">
                      {{ 'FIELD_EMAIL' | translate }}
                    </div>
                  </div>
                </div>
              </div><!--col-->

              <div class="col-xxl-3 col-md-4">
                <lockbin-custom-autocomplete id="customAutocompleteCustomer" #customAutocompleteCustomer
                  [isRequired]="false"
                  (onSelectedItem)="doSelectItemAutocompleteCustomer($event)" [searcher]="searcherCustomer$"
                  labelInputText="Cliente" placeholderText="Buscar cliente..." fieldToFilter="name" fieldToShow="name">
                </lockbin-custom-autocomplete><!--custom autocomplete-->
              </div><!--col-->


            </div><!--row-->
          </div>
        </div><!-- .card-inner -->

        <div class="card-inner">
          <div class="nk-block">
            <div class="row gy-4">
              <div class="col">

                <div class="d-flex justify-content-between">
                  <button (click)="onClickSubmit()" class="btn btn-primary"
                    [lockbinButtonLoading]="this.formGroup.pending || this.storeService.getIsLoading()"
                    [disabled]="formGroup.pending || formGroup.invalid">
                    <span>{{ 'BTN_SAVE' | translate }}</span>
                    <em class="icon ni ni-save"></em>
                  </button>
                  <button (click)="goBack()" class="btn btn-outline-light bg-white">
                    <em class="icon ni ni-arrow-left"></em>
                    <span>{{ 'BTN_BACK' | translate }}</span>
                  </button>
                </div>

              </div>
            </div><!--row-->
          </div>
        </div><!-- .card-inner -->
      </div>
    </div><!-- .card -->

  </div><!-- .nk-block -->
</form>

<div class="code-debug" *ngIf="!environment.production">
  <code class="debug">
  <p>Form Valid: {{ formGroup.valid }}</p>
  <p>Form Invalid: {{ formGroup.invalid }}</p>
  <p>Form Status: {{ formGroup.status }}</p>
  <p>Form Pending: {{ formGroup.pending }}</p>
  <p>Form errors</p>

  <pre>{{ formGroup.errors | json }}</pre>

  <p>Nombre</p>
  <pre>{{ formGroup.controls.newPassword.value | json }}</pre>
  <pre>{{ formGroup.controls.newPassword.errors | json }}</pre>
  <pre>{{ formGroup.hasError('passwordAreDiferents') | json }}</pre>




  </code>
</div>

