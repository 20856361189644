
<div class="nk-block">
  <div class="row gy-4 mb-3">

    <div class="col-xxl-8 col-md-8 col-lg-8">
      <div class="form-group">
        <label class="form-label" for="name">Nombre del rol</label>
        <div class="form-control-wrap">
            <input type="text" id="name" name="name" class="form-control form-control-readonly" readonly value="{{ entity.name }}">
        </div>
      </div>
    </div><!--col-->

  </div><!--row-->
</div><!--block-->


<div class="nk-block" *ngIf="false">
  <div class="row gy-2">
    <div class="col-xxl-6 col-md-6">
      <div class="form-group">
        <button (click)="gotoEditPage($event)" class="btn btn-primary">
          {{ 'BTN_EDIT' | translate }}
        </button>
      </div>
    </div><!--col-->
  </div><!--row-->
</div><!--block-->
