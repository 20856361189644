// Angular modules
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import { NGXLogger } from 'ngx-logger';
import Swal from 'sweetalert2';

// Base class
import { BaseListComponent } from '@pages/base/base-list.component';

// Components
import { DataListEventRowOperation, DataListRowOperationAlias } from '@models/index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import RoleQuickviewComponent from '../role-quickview/role-quickview.component';

// Helpers
import { RoleDataListConfigurerHelper } from '@security/helpers/role-datalist-configurer.helper';

// Models
import { Role, RoleExporter } from '@security/models';

// Services
import { StoreService } from '@services/store.service';
import { EventBusService } from '@services/event-bus.service';
import { RoleService } from '@security/services/role.service';


@Component({
  selector: 'lockbin-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: [],
})
export class RoleListComponent extends BaseListComponent<Role> implements OnInit {


  // -------------------------------------------------------------------------------
  // #region Constructor & Init & Destroy ------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override logger: NGXLogger,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
    protected override modalService: NgbModal,
    protected override renderer: Renderer2,
    protected override translateService: TranslateService,
    protected override router: Router,
    private roleService: RoleService,
  ) {
    super(location, titleService, storeService, logger, activatedRoute, eventBusService, modalService, renderer, translateService, router);
  }


  override ngOnInit(): void {

    super.ngOnInit();

    // setup instruccions to do when operations are exectuted in base class
    this.setupEventForBaseClassOperations();

    this.setupRoutes();

    // do default setup for modal
    this.setupModal();

    // do default setup for this page
    this.setupDataList();

    this.setupExport();

    // setups observables for finders
    this.setupFinderObservables();

    // set itemsPerPage after doing default setup
    this.initPagination(this.dataListWrapper.header.pagination.itemsPerPage);

    this.doGetAll(); // TODO: Recuperar filtro del LocalStorage. Si existe => aplicar filtro. Si no exite => getall
  }
  // #endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Setup Routes  ---------------------------------------------------------
  // -------------------------------------------------------------------------------
  /**
  * Set routes to list/edit after page operation (add or edit) is done
  */
  private setupRoutes() {

    this.routeToAdd = '/administracion/seguridad/roles-add/';
    this.routeToEdit = '/administracion/seguridad/roles-edit/';
  }
  // #endregion
  // -------------------------------------------------------------------------------


    // -------------------------------------------------------------------------------
  // #region Setup Observable Finders  --------------------------------------------
  // -------------------------------------------------------------------------------

  // setups Observables for finders
  private setupFinderObservables() {

    this.paramsWithFilterSubject
      .pipe().subscribe((params: any) => {
        this.obsFindByParams$ = this.roleService.find(params);
      });

  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  //#region Modal Setup
  setupModal() {

    this.componentModal = RoleQuickviewComponent;

  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region DataList setup
  /**
  * Do setup for datalist presentation: useCheckbox, useSearch, header, etc.
  */
  private setupDataList() {
    // initialize specific helper
    this.dataListConfigurerHelper = new RoleDataListConfigurerHelper();

    this.dataListWrapper = this.getDefaulDataListWrapperBasicSetup();

    this.dataListWrapper.header = this.getDatataListHeaderSetup();
    this.dataListWrapper.rowOperations = this.getRowOperationsToShow();
    this.dataListWrapper.bulkOperations = this.getBulkOperationsToShow();
  }


  // configure exporter for this entity
  private setupExport() {

    this.exporter = new RoleExporter();
    this.fileNameExportedData = 'Roles';
    this.workSheetName = 'Hoja 1';
  }

  // #endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region RowOperations

  doRowClick(eventRowOperation: DataListEventRowOperation) {
    switch (eventRowOperation.operation) {

      case DataListRowOperationAlias.Delete:
      this.doDelete(eventRowOperation.item.id);
      break;

      case DataListRowOperationAlias.Edit:
      this.gotoEditPage(eventRowOperation.item.id);
      break;

      case DataListRowOperationAlias.ViewDetails:
      console.log(eventRowOperation.item);
      this.showModalQuickView(eventRowOperation.item.content);
      break;

      default:
      break;
    }

    this.logger.info('Click on operation:', eventRowOperation.operation, 'item:', eventRowOperation.item);
  }


  private doDelete(itemId: number) {

    if (!this.storeService.getIsLoading()) {

      Swal.fire({
        title: this.translateService.instant('ALERT_DELETE_ENTRY'),
        text: this.translateService.instant('ALERT_CONFIRM_TEXT'),
        showClass: { popup: 'animated fadeInDown ', },
        // icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
        cancelButtonText: this.translateService.instant('BTN_CANCEL'),
      }).then((result) => {

        if (result.isConfirmed) {

          const obs$ = this.roleService.delete(itemId);

          this.delete(obs$);
        }
      });
    }
  }


  private gotoEditPage(itemId: number) {

    this.router.navigate([`${this.routeToEdit}${itemId}`]);

  }
  //#endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region BulkOperations
  doBulkOperation(operation: string) {

    const idsToDoBulkOperation = this.getIdsToDoBulkOperation();

    if (!idsToDoBulkOperation || idsToDoBulkOperation.length === 0) return;

    Swal.fire({
      title: this.translateService.instant('ALERT_BULK_HEADER') + ` (${idsToDoBulkOperation?.length})`,
      text: this.translateService.instant('ALERT_CONFIRM_TEXT'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
      cancelButtonText: this.translateService.instant('BTN_CANCEL'),
    }).then((result) => {

      if (result.isConfirmed) {
        switch (operation) {
          case DataListRowOperationAlias.Delete:
          this.doDeleteBulkOperation(idsToDoBulkOperation);
          break;

          default:
          break;
        }
      }
    })
  }


  doDeleteBulkOperation(idsToDoBulkOperation: number[]) {

    if (!this.storeService.getIsLoading()) {

      const obs$ = this.roleService.deleteBulk(idsToDoBulkOperation);

      this.deleteBulk(obs$);

    }
  }
  //#endregion
  // -------------------------------------------------------------------------------



}
