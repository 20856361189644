// Angular modules
import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

// Components
import { RolesComponent } from './roles/roles.component';
import { SecurityComponent } from './security.component';

import { UserListComponent } from './users/pages/user-list/user-list.component';
import { UserAddComponent } from './users/pages/user-add/user-add.component';

import { RoleAddComponent } from './roles/pages/role-add/role-add.component';
import { RoleListComponent } from './roles/pages/role-list/role-list.component';

const routes: Routes = [
  {
    path: '',
    component: SecurityComponent,
    children: [
      { path: '', redirectTo: 'usuarios', pathMatch: 'full' },

      { path: 'roles', component: RolesComponent },

      { path: 'usuarios-list', component: UserListComponent },
      { path: 'usuarios-add', component: UserAddComponent },
      { path: 'usuarios-edit/:id', component: UserAddComponent },

      { path: 'roles-list', component: RoleListComponent },
      // { path: 'roles-add', component: RoleAddComponent },
      // { path: 'roles-edit/:id', component: RoleAddComponent },

      { path: '', redirectTo: '/administracion/seguridad/usuarios/list', pathMatch: 'full' },

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
  ]
})
export class SecurityRoutingModule { }
